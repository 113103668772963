:root {
  --background-color: #e6e6e6;
  --text-color: black;
  --muted-text-color: #636363;
  --very-muted-text-color: #a0a0a0;

  --calculator-button-color: #f0f0f0;
  --calculator-button-dark-color: #fafafa;
  --calculator-button-light-color: #a3a3a3;
  --calculator-button-hover-color: #b3b3b3;
  --calculator-button-active-color: #a5a5a5;

  --scrollbar-thumb-color: #686868;
  --scrollbar-thumb-hover-color: #555;
}

:root .theme-dark {
  --background-color: #1f1f1f;
  --text-color: white;
  --muted-text-color: #c5c5c5;
  --very-muted-text-color: #646464;

  --calculator-button-color: #131313;
  --calculator-button-dark-color: #060606;
  --calculator-button-light-color: #2c2c2c;
  --calculator-button-hover-color: #4b4b4b;
  --calculator-button-active-color: #5f6164;

  --scrollbar-thumb-color: #686868;
  --scrollbar-thumb-hover-color: #555;
}

body {
  margin: 0;
  position: relative;
  color: var(--text-color);
  background: var(--background-color);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font-family: monospace;
}

html {
  touch-action: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

.calculator {
  --outside-padding: 3px;
  width: calc(100% - max(env(safe-area-inset-right), var(--outside-padding)) - max(env(safe-area-inset-left), var(--outside-padding)));
  height: calc(100% - max(env(safe-area-inset-bottom), var(--outside-padding)) - max(env(safe-area-inset-top), var(--outside-padding)));
  margin-left: env(safe-area-inset-left);
  margin-top: env(safe-area-inset-top);
  padding: var(--outside-padding);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    'calculator-top calculator-top calculator-top calculator-top'
    'calculator-top calculator-top calculator-top calculator-top'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
    'calculator-buttons calculator-buttons calculator-buttons calculator-buttons';
}

@media (orientation: landscape) {
  .calculator {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'calculator-top calculator-top calculator-top calculator-top calculator-top calculator-top calculator-top'
      'calculator-top calculator-top calculator-top calculator-top calculator-top calculator-top calculator-top'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons'
      'calculator-extra-buttons calculator-extra-buttons calculator-extra-buttons calculator-buttons calculator-buttons calculator-buttons calculator-buttons';
  }
}

.calculator-top {
  grid-area: calculator-top;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.calculator-stack {
  width: 100%;
  color: var(--muted-text-color);
  overflow-y: auto;
  padding-right: 10px;
  margin-top: auto;
}

.calculator-buffer {
  font-size: 3em;
  padding-right: 10px;
  white-space: nowrap;
}

.calculator-buttons {
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-area: calculator-buttons;
  display: grid;
}

.calculator-extra-buttons {
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: calculator-extra-buttons;
  display: none;
}

@media (orientation: landscape) {
  .calculator-extra-buttons {
    display: grid;
  }
}

.calculator-button {
  padding: 0;
  margin: 1px;
  font-size: 2em;
  color: var(--text-color);
  border: none;
  background: var(--calculator-button-color);
  align-items: center;
  justify-content: center;
  user-select: none;
}

.calculator-button:focus-visible {
  outline: none;
}

.calculator-button-dark {
  background: var(--calculator-button-dark-color);
}

.calculator-button-light {
  background: var(--calculator-button-light-color);
}

.calculator-button.hovered {
  background: var(--calculator-button-hover-color);
}

.calculator-button.pressed,
.calculator-button-active {
  background: var(--calculator-button-active-color);
}

.hidden {
  display: none;
}

sup,
sub {
  font-size: 60%;
}

.version {
  position: fixed;
  color: var(--very-muted-text-color);
  padding-left: env(safe-area-inset-left);
  top: 5px;
  left: 5px;
  pointer-events: none;
  user-select: none;
}

.version a {
  pointer-events: all;
  color: var(--very-muted-text-color);
}

.text-decoration-overline {
  text-decoration: overline;
  text-decoration-thickness: from-font;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}
